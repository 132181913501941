@import '../../assets/styles/_variables';

.section-outer {
  padding: 0 $base * 4;
 }  // height: 100%
.section-inner {
  max-width: $container-width;
  margin: 0 auto; }

.main-gallery {
  &_fetching {
    opacity: 0.6; }
  &__gallery {}
  &__pagination {} }

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: clamp(1rem, $base * 4, $base * 6);
  justify-content: flex-start;
  &_single {
    grid-template-columns: 0.3fr;
    @media (max-width: $breakpoint-lg-max) {
      grid-template-columns: 0.5fr; }
    @media (max-width: $breakpoint-sm-min) {
      grid-template-columns: 1fr; } }
  &__item {
    min-height: 200px;
    max-height: 200px;
 } }    // max-height: 300px

.filter-param-container {
  padding-top: $base * 6;
  &__label {
    font-size: $fs-xs;
    color: $accent;
    display: inline-block;
    margin-bottom: $base; }
  .react-select {
    &__control {
      padding: 0 !important; } } }

.materials-page {
  &__search {
    padding: $base * 3 0; }
  &__filters {
    padding: $base * 4 0; }
  &__gallery {
    padding-top: $base * 3; } }

.pb-link {
  &_active {
    font-weight: 600; } }

.bottom-indent {
  padding-bottom: $base * 10; }

.filter-param-container {
  .react-select {
    &__control {
      border-color: $accent;
      background-color: $background-dark;
      &:hover {
        border-color: $accent; } } }
  &__label {
    font-size: $fs-xs;
    color: $accent;
    display: inline-block;
    margin-bottom: $base;
    @media (max-width: $breakpoint-sm-min) {
      color: $text-light; } } }
