@import '../../assets/styles/_variables';

.section-details {
  height: 100%;
  background-color: $background-dark;
  color: $text-inactive;
  @media (max-width: $breakpoint-sm-min) {
    background-color: $accent;
    color: $text-light; } }

.details-page {
  &__search {
    padding: $base * 3 0; }
  &-info {
    padding: $base * 2;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: minmax(40%, 600px) 48%;
    grid-template-areas: "info img" "info download";
    grid-column-gap: $base * 10;
    grid-row-gap: $base * 6;
    border: 1px solid $color-border;
    border-radius: $border-radius;
    @media (max-width: $breakpoint-md-max) {
      grid-template-rows: repeat(3, auto);
      grid-template-columns: 1fr;
      grid-template-areas: 'img' 'download' 'info'; }
    @media (max-width: $breakpoint-md-min) {
      justify-content: center; }
    @media (max-width: $breakpoint-sm-min) {
      grid-row-gap: $base * 4;
      color: $text-light;
      border: none; }
    &__img {
      grid-area: img;
      max-height: 450px;
      border-radius: $border-radius;
      background-color: rgba(0,0,0,.03);
      @media (max-width: $breakpoint-md-max) {
        justify-self: center;
        max-width: 600px; }
      @media (max-width: $breakpoint-sm-min) {
        max-height: 300px; } }
    &__download {
      grid-area: download;
      justify-self: center;
      width: 100%;
      @media (max-width: $breakpoint-md-max) {
        max-width: 600px; } }

    &__sub {
      grid-area: info; } } }
