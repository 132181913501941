@import '../../assets/styles/_variables';

.skeleton-news {
  width: 100%;
  padding: $base * 10 0;
  &-today {
    grid-column: span 2;
    grid-row: span 2;
    overflow-x: hidden;
    overflow-y: auto;
    &__title {
      width: 50%;
      height: 30px;
      margin-bottom: $base * 5; }
    &-news {
      width: 100%;
      margin-bottom: $base * 5;
      &__text {
        width: 100%;
        height: $base * 4;
        margin-bottom: $base;
        &:last-child {
          width: 60%; } } } }
  &__item {
    height: 200px;
    width: 100%; } }
