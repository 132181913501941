@import '../../assets/styles/_variables';

.empty-result {
  margin-top: $base * 10;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &__title {
    color: $accent;
    @media (max-width: $breakpoint-sm-min) {
      color: $text-light;
      text-align: center; } } }
