@import '../../assets/styles/_variables';
@import '../../assets/styles/_mixins';

.autocomplete-wrapper {
  position: relative;
  &__input {
    width: 100%;
    // border-radius: $border-radius
    // border: 1px solid $color-divider
    // padding: $base * 5 $base * 18 $base * 5 $base * 4
    // line-height: 17px
    font-size: $fs-s;
    // background-color: $background-dark
    background: transparent;
    border: none;
    &::placeholder {
      font-size: $fs-s;
      color: $text-main;
      @include Montserrat; }
    &:focus {
      outline: none; }
    &:focus-visible {
      outline: none;
      // border: 1px solid $color-focus
 } }      // outline-offset: 1px
  &__remove {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    vertical-align: middle;
    // width:
    svg {
      display: block;
      vertical-align: bottom; } } }
