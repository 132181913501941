@import '../../assets/styles/_variables';
@import '../../assets/styles/_mixins';

.search {
  &-field {
    position: relative;
    border-radius: $border-radius;
    &__input {
      width: 100%;
      border-radius: $border-radius;
      border: 1px solid $color-divider;
      padding: $base * 4 $base * 18 $base * 4 $base * 4;
      line-height: 17px;
      // font-size: $fs-s
      background-color: $background-dark;
      &::placeholder {
        font-size: $fs-s;
        @include Montserrat; }
      &:focus-visible {
        outline: none;
        // border: 1px solid $color-focus
        outline-offset: 1px; } }
    &-icons {
      position: absolute;
      top: 50%;
      right: $base * 2;
      transform: translateY(-50%);
      display: flex;
      justify-content: flex-start;
      gap: $base;
      align-items: center;
      &__item {
        height: $base * 6;
        width: $base * 6;
        cursor: pointer;
        path {
          fill: #BDBDBD; }
        &_close {
          &:hover {
            color: $color-danger; } }
        &_search {
          &:hover {
            path {
              fill: $color-focus; } } } } } } }
