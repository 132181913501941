@import '../../assets/styles/_variables';

.information-page {
  color: $accent;
  @media (max-width: $breakpoint-sm-min) {
    color: $text-light; }
  &__text {
    font-size: $fs-m;
    line-height: 1.5em;
    @media (max-width: $breakpoint-sm-min) {
      color: $text-light; }
    &-medium {
      font-weight: $fw-bold; } }
  &__paragraph {}
  &__list {
    display: flex;
    flex-direction: column;
    gap: $base * 2;
    &-item {} } }
