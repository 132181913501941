@import '../../assets/styles/_variables';
@import '../../assets/styles/_mixins';

.custom-button {
  @include Montserrat;
  font-size: $fs-s;
  font-weight: 600;
  line-height: $fs-lg;
  border-radius: $border-radius;
  outline: none;
  padding: $base * 2;
  color: $text-light;
  min-width: $base * 60;
  cursor: pointer;
  transition: $transition;
  &_short {
    min-width: 0;
    padding: 0.4em; }
  &_size {
    &_sm {
      font-size: 0.75em; }
    &_lg {
      font-size: 1.25em; } }
  &_type {
    &_primary {
      background: $accent;
      &:hover {
        background-color: lighten($accent, 15%);
        transition: $transition; } }
    &_secondary {
      border: 1px solid $accent;
      background: transparent;
      color: $accent;
      &:hover:not(&:disabled) {
        color: lighten($accent, 15%);
        transition: $transition; }
      .custom-button_loading {
        background-color: transparent; } }
    &_danger {
      background: $color-danger;
      &:hover {
        background-color: lighten($color-danger, 5%);
        transition: $transition; } }
    &_transparent {
      background: none !important;
      color: $accent; }
    &_grey {
      background: $color-button-grey;
      color: $text-light;
      &:hover {
        background: lighten($color-button-grey, 5%);
        transition: $transition; } } }
  &_fsize {
    width: 100%; }
  &_bnone {
    border: none; }
  &_loading {
    pointer-events: none;
    &:not(.custom-button_type_secondary) {
      background-color: $color-loading; }
 }    // cursor: wait
  &:disabled {
    background-color: $color-button-disable;
    cursor: not-allowed; }
  &:focus {
    border-color: $text-main; } }
