@import '../../assets/styles/_variables';
@import '../../assets/styles/_mixins';

.img-stat-wrapper {
  position: absolute;
  inset: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
  border-radius: $border-radius;
  z-index: 2;
  padding: $base * 2;
  display: flex;
  flex-direction: column;
  &-statistics {
    @include Inter;
    color: $text-light;
    font-size: $fs-xs;
    flex-grow: 1;
    cursor: pointer;
    text-decoration: none;
    &__date {}
    &__author {}
    &__category {}
    &__date, &__author, &__category {
      display: block;
      margin-bottom: $base * 2;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; } }
  &__favourite {
    color: $text-light;
    width: 30%;
    margin-left: auto;
    text-align: right;
    &-title {
      font-size: $fs-l; } } }
