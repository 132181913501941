@import '../../assets/styles/_variables';
@import '../../assets/styles/_mixins';

.auth-module-wrapper {
  @include Montserrat;
  &-role {
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    &__login, &__register {
      background-color: $accent;
      text-align: center;
      color: $text-light;
      font-weight: 600;
      font-size: $fs-s;
      padding: $base * 3 0;
      border-top-left-radius: $base * 5;
      border-top-right-radius: $base * 5;
      cursor: pointer;
      flex-basis: 47%;
      @media (max-width: $breakpoint-sm-min) {
        font-size: $fs-xs; }
      &_active {
        background-color: #fff;
        color: $accent; } } }
  &-form {
    background-color: #fff;
    padding: $base * 16;
    border-bottom-left-radius: $base * 5;
    border-bottom-right-radius: $base * 5;
    min-width: 500px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media (min-width: $breakpoint-xl-min) and (min-resolution: 192dpi) {
      min-width: 350px;
      max-width: 450px; }
    @media (max-width: $breakpoint-sm-min) {
      min-width: 100px;
      padding: $base * 6 $base * 10; }
    .auth-module {
      width: 100%;
      &__title {
        color: $accent;
        margin-bottom: $base * 10;
        font-weight: 600;
        font-size: $fs-lg;
        margin-top: 0; }
      &-form {
        width: 100%;
        &__control {
          font-size: $fs-s;
          margin-bottom: $base * 10; }
        &__link {
          display: block;
          margin-top: $base * 4;
          color: $color-focus;
          font-size: $fs-s;
          text-align: center; } }

      &__error {
        @include Montserrat;
        font-size: $fs-s;
        line-height: $fs-s;
        margin-top: $base * 4;
        display: block;
        color: $color-danger;
        text-align: center; } } } }
