@import '../../assets/styles/_variables';
@import '../../assets/styles/_mixins';

.section-header {
  background-color: #fff;
  box-shadow: 0px 4px 30px 5px rgba(222, 226, 230, 0.2);
  padding-top: $base * 4;
  padding-bottom: $base * 4;
  @media (max-width: $breakpoint-sm-min) {
    background-color: $accent;
    box-shadow: none; } }
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  &__logo {
    flex-basis: $base * 40;
    min-width: $base * 40;
    margin-right: $base * 3; }
  &__img {
    vertical-align: middle;
    .st1 {
      fill: $accent; }
    @media (max-width: $breakpoint-sm-min) {
      .st1 {
        fill: $text-light; } } }
  &-nav-group {
    display: flex;
    align-items: center; }
  &-menu {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    @include list-null;
    &__item {
      display: block;
      margin-right: $base * 26;
      font-size: $fs-s;
      text-decoration: none;
      color: #000;
      @media (max-width: $breakpoint-lg-max) {
        margin-right: $base * 8; }
      @media (max-width: $breakpoint-sm-min) {
        display: none; } } }

  &__btn {
    margin-left: $base * 20;
    font-size: $fs-xs;
    padding-left: $base * 7;
    padding-right: $base * 7;
    @media (max-width: $breakpoint-lg-max) {
      margin-left: $base * 2; } } }
