@import '../../assets/styles/_variables';
@import '../../assets/styles/_mixins';

.section-footer {
  background-color: #07002D;
  padding-top: $base * 14;
  padding-bottom: $base * 4;
  margin-top: auto;
  // grid-area: footer
  @include Montserrat;
  color: $text-light; }
.footer {
  height: 100%;
  &-content {
    @include Montserrat;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: 'info main account';
    max-width: 1700px;
    grid-column-gap: $base * 20;
    justify-content: center;
    @media (max-width: $breakpoint-md-min) {
      grid-template-rows: repeat(2, 1fr);
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas: 'info account' 'main main';
      // justify-content: center
      grid-column-gap: 0;
      grid-row-gap: $base * 12; }
    &-main {
      grid-area: main;
      justify-self: center;
      &__body {
        text-decoration: none;
        width: 100%;
        margin-top: $base * 7;
        display: flex;
        gap: $base * 4;
        align-items: center;
        &:hover {
          & > span {
            color: $text-light;
            transition: $transition; } } }
      &__logo {
        width: 100%;
        height: 100px;
        // display: inline-block
        .cls-1 {
          display: none; }
        .cls-2 {} }
      &__text {
        color: $text-inactive;
        transition: $transition; }
      &-menu {
        @include list-null();
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(2, min-content);
        grid-row-gap: $base * 4;
        grid-column-gap: $base * 10;
        grid-template-rows: repeat(2, 1fr);
        @media screen and (max-width: $breakpoint-xl-min) {
          grid-template-columns: 1fr;
          grid-template-rows: repeat(4, 1fr);
          grid-auto-flow: column; }
        @media (max-width: $breakpoint-sm-min) {
          align-items: center;
          grid-row-gap: $base* 2; }
        &__item {
          display: block;
          white-space: nowrap;
          font-size: $fs-s;
          @media (max-width: $breakpoint-sm-min) {
            white-space: pre-wrap; }
          a {
            color: $text-light;
            text-decoration: none; } } } }
    &-info {
      grid-area: info;
      justify-self: center; }
    &-account {
      grid-area: account;
      justify-self: center; }
    &-block {
      @media (max-width: $breakpoint-md-min) {
        text-align: center; }
      &__title {
        font-size: $fs-m;
        margin: 0;
        text-align: center; }
      &-menu {
        @include list-null();
        margin-top: $base * 7;
        font-size: $fs-s;
        &__item {
          margin-bottom: $base * 3;
          white-space: nowrap;
          a {
            color: $text-placeholder;
            text-decoration: none; } } } } }
  &-copyrights {
    margin-top: $base * 20;
    font-size: $fs-s;
    color: $text-placeholder;
    display: block;
    @media (max-width: $breakpoint-sm-min) {
      text-align: center;
      margin-top: $base * 4; } } }
