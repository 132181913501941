@import '../../assets/styles/_variables';

.user-profile {
  position: absolute;
  z-index: 10;
  right: 0;
  top: 0;
  max-width: 450px;
  box-shadow: -2px 4px 30px rgba(222, 226, 230, 0.3);
  transition: .5s;
  border-bottom-left-radius: $border-radius;
  border: 1px solid transparent;
  &__close {
    display: block;
    position: absolute;
    right: $base * 4;
    top: $base * 5;
    background-color: $accent;
    width: $base * 11;
    height: $base * 11;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
  &-content {
    background-color: #fff;
    padding: $base * 18 $base * 7 $base * 7;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &__name {
      font-size: $fs-xl;
      font-weight: 600;
      color: $accent; }
    &__link {
      padding: $base * 3 0;
      text-decoration: none;
      color: $text-placeholder;
      transition: $transition;
      &_mobile {
        display: none;
        @media (max-width: $breakpoint-sm-min) {
          display: block; } }
      &_btn {
        cursor: pointer; }
      &:not(span):hover {
        transition: $transition;
        color: $accent; }
      span {
        color: $accent;
        font-weight: bold;
        font-size: $fs-m; } }
    &__btn {
      margin-top: $base * 8; } } }
