@import './_variables';
@import './_mixins';
@import './_shared';

.App {
  position: relative;
  // height: 100vh
  // height: 100vh
  // overflow-x: hidden
  @include Montserrat();
  @media (max-width: $breakpoint-sm-min) {
    background-color: $accent; } }

.page {
  display: grid;
  grid-template-rows: auto minmax(100vh, 1fr) auto;
  grid-template-areas: header page footer;
  // display: flex
  // flex-direction: column
  background-color: $background-dark;
  // min-height: 100%
  @media (max-width: $breakpoint-sm-min) {
    background-color: $accent; } }

.react-select-container {
  @include Inter();
  font-size: $fs-xs;
  text-align: left;
  .react-select {
    &__control {
      border-color: $border-color;
      border-radius: $border-radius;
      // padding: $base
      &:hover {
        border-color: $border-color; }
      // &--menu-is-open
      //   border-color: $border-color
      &--is-focused {
        border-color: $border-color;
        box-shadow: none; } }
    &__value-container {} // непосредственно строка до открытия
    &__indicator {
      color: $accent;
      &-separator {
        visibility: hidden; } }
    &__menu { // выпадающее меню
      z-index: 4;
      &-list {
        @media (max-width: $breakpoint-sm-min) {
          color: $accent; } } }
    &__option {
      &--is-focused {}
      &--is-selected {
        color: $text-light;
        background-color: $accent; } } } }
