@import '../../../assets/styles/_variables';

.authors-module {
  &-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-gap: clamp(1rem, $base * 2, $base * 4);
    @media (max-width: $breakpoint-sm-min) {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); }
    &__item {
      height: 160px; } }
  &-actions {
    margin-top: $base * 8;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &__pagination {
      margin-left: auto; } } }
