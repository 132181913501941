@import '../../assets/styles/_variables';

.skeleton-material-detail {
  width: 100%;
  &__title {
    height: $base * 7;
    width: 30%;
    margin: $base * 4 0; }
  &__text {
    height: $base * 5;
    width: 60%; } }
