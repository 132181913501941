@import '../../assets/styles/_variables';
@import '../../assets/styles/_mixins';

.input_borderless {
  @include Inter;
  display: inline-block;
  font-size: $fs-s;
  padding: $base 0;
  background-color: transparent;
  width: 100%;
  border: none;
  border-bottom: 1px solid $text-inactive;
  outline: none;
  transition: $transition;

  &:focus {
    transition: $transition;
    color: $color-focus;
    border-color: $color-focus; }
  &:invalid {
    transition: $transition;
    border-color: red; }
  &::placeholder {
    transition: $transition;
    color: $text-placeholder; } }
