@import '../../assets/styles/_variables';
.skeleton-main-gallery {
  width: 100%;
  &__title {
    margin-bottom: $base * 10;
    margin-top: $base * 4;
    margin: $base * 4 auto $base * 10;
    display: block;
    width: 40%;
    height: $base * 6; }
  &-items {
    &__item {
      width: 100%;
      height: 200px; } } }
