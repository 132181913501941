@import '../../assets/styles/_variables';
@import '../../assets/styles/_mixins';

.autocomplete-options {
  position: absolute;
  top: 110%;
  left: 0;
  right: 0;
  border-radius: $border-radius;
  // min-height: 50px
  // max-height: 200px
  overflow-y: auto;
  background-color: $background-dark;
  border: 1px solid $border-color;
  font-size: $fs-xs;
  text-align: left;
  box-shadow: 0 6px 8px 1px rgba(0, 0, 0, 0.2);
  z-index: 2;
  &__empty {
    font-size: $fs-xs;
    display: block;
    padding: $base * 4;
    text-align: center; }
  &-list {
    @include list-null;
    padding: $base * 2 0;
    // @include Montserrat
    &__item {
      padding: $base*2 $base * 4;
      cursor: pointer;
      color: $text-main;
      font-size: $fs-s;
      &:hover {
        background-color: #DEEBFF; } } } }
