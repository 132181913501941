@import '../../assets/styles/_variables';

.filters-module {
  &-actions {
    display: flex;
    &__title {
      display: block;
      color: $accent;
      font-size: $fs-l;
      @media (max-width: $breakpoint-sm-min) {
        color: $text-light; } }
    &__item {
      margin-left: auto;
      display: flex; } }
  &-settings {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    &_hidden {
      display: none; }
    @media (max-width: $breakpoint-sm-min) {
      justify-content: center; }
    &__control {
      flex-basis: 200px;
      margin-right: $base * 4;
      margin-bottom: $base * 4;
      @media (max-width: $breakpoint-sm-min) {
        flex-grow: 1; } }
    &__btn {
      margin-left: auto;
      // align-self: flex-end
      @media (max-width: $breakpoint-md-min) {
        margin-left: 0; }
      @media (max-width: $breakpoint-sm-min) {
        color: $text-light;
        width: 100%; } } } }
