@import '../../assets/styles/_variables';
@import '../../assets/styles/_mixins';

table {
  text-align: left;
  border-collapse: collapse;
  width: 100%;
  font-weight: 400;
  table-layout: fixed; }

table thead {
  @include Inter;

  border-bottom: 1px solid $border-color;
  th {
    font-size: $fs-s;
    font-weight: 400;
    color: $text-inactive;
    white-space: break-spaces;
    text-overflow: ellipsis;
    overflow: hidden; } }

table tbody {
  tr {
    opacity: 0.4;
    color: $accent;
    transition: $transition;
    cursor: pointer;

    &:hover {
      opacity: 1;
      transition: $transition;
      .cell_colored:last-child {
        color: $color-active-cell; }
      .cell_green {
        color: $color-active-cell; }
      .cell_red {
        color: $color-danger; } }
    td {
      padding: $base * 2 $base * 2 $base * 2 0;
      font-size: $fs-l;
      white-space: break-spaces;
      text-overflow: ellipsis;
      overflow: hidden; } } }

table th {
  padding: $base * 2 $base * 2 $base * 2 0;
  text-align: left;
  color: $text-main; }

.empty-cell {
  text-align: center; }
.cell__link {
  text-decoration: none;
  color: $accent;
  svg {
    fill: $accent;
    stroke: $accent; } }
