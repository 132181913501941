@import "react-datepicker/dist/react-datepicker.css";
@import '../../assets/styles/_variables';

.date-picker-wrapper {
  position: relative;
  font-size: $fs-s;
  width: 100%;
  label {
    color: $text-inactive;
    display: block;
    padding: 0.5em 0; } }
.date-picker {
  border: 1px solid $border-color;
  border-radius: $base * 2;
  display: inline-block;
  padding: 0.75em 1em;
  width: 100%;
  transition: $transition;
  outline: none;
  &:focus:not(&_error) {
    transition: $transition;
    color: $color-focus;
    border-color: $color-focus; }
  &_error {
    border-color: red; } }
.react-datepicker__day--selected {
  background-color: $accent; }
