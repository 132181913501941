@import '../../assets/styles/_variables';

.skeleton-actions {
  display: flex;
  flex-wrap: wrap;
  &__btn {
    width: $base * 60;
    height: $base * 10;
    margin-bottom: $base * 2; }
  &__pagination {
    margin-left: auto;
    width: $base * 45;
    height: $base * 10;
    @media (max-width: $breakpoint-sm-min) {
      margin-left: 0; } } }
