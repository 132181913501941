@import './_variables';
@import './toast.css';

html {
  height: 100vh;
  @media (min-width: $breakpoint-sm-min) {
    font-size: 14px; }
  @media (min-width: $breakpoint-md-min) {
    font-size: 16px; }
  @media (min-width: $breakpoint-xl-min) {
    font-size: 18px; }
  @media (min-width: $breakpoint-fhd-min) {
    font-size: 20px; }

  @media (min-width: $breakpoint-xl-min) and (min-resolution: 192dpi) {
    font-size: 16px; } }

*, *:before, *:after {
  box-sizing: border-box; }
body {
  margin: 0;
  padding: 0; }

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0); }
