@import '../../assets/styles/_variables';
@import '../../assets/styles/_mixins';

.section-authors {
  color: $accent; }
.authors-page {
  &__search {
    padding: $base * 4 0; }
  @media (max-width: $breakpoint-sm-min) {
    color: $text-light; } }
