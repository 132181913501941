@import '../../assets/styles/_variables';

.filter-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $fs-s;
  color: $text-inactive;
  cursor: pointer;
  transition: $transition;
  &_active {
    color: $accent;
    transition: $transition;
    @media (max-width: $breakpoint-sm-min) {
      color: $text-light; }
    .filter-icon__img {
      path {
        fill: $accent;
        @media (max-width: $breakpoint-sm-min) {
          fill: $text-light; } } } }
  &__title {
    display: block;
    // @media (max-width: $breakpoint-sm-min)
 }    //   display: none
  &__img {
    display: block;
    width: 20px;
    height: 20px;
    margin-left: $base * 2;
    path {
      fill: $text-inactive; } } }
