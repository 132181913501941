@import '../../assets/styles/_variables';

.skeleton-windows {
  &-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-gap: clamp(1rem, $base * 2, $base * 4);
    justify-content: center;
    @media (max-width: $breakpoint-sm-min) {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); } }
  &-actions {
    margin-top: $base * 8;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &__pagination {
      width: 100px;
      height: 20px;
      margin-left: auto; } } }
