@import '../../assets/styles/_variables';
@import '../../assets/styles/_mixins';

.popup {
  @include Inter();
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  padding: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  &__close {
    position: absolute;
    width: $base * 8;
    height: $base * 8;
    top: $base * 8;
    right: $base * 8;
    opacity: 0.3;
    cursor: pointer;
    &:hover {
      opacity: 1; }
    &:before, &:after {
      position: absolute;
      left: $base * 4;
      content: ' ';
      height: $base * 8;
      width: $base / 2;
      background-color: #333; }
    &:before {
      transform: rotate(45deg); }
    &:after {
      transform: rotate(-45deg); } }
  &__content {
    z-index: 1; } }

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.6);
  cursor: pointer; }
