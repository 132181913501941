@import '../../../../assets/styles/_variables';
@import '../../../../assets/styles/_mixins';

.material-info {
  @include Inter;
  width: 100%;
  // display: grid
  // grid-template-columns: repeat(auto-fit, minmax(250px, 1fr))
  // grid-gap: clamp(1rem, $base * 4, $base * 6)
  // justify-content: flex-start
  // @media (max-width: $breakpoint-xs-min)
  //// grid-template-columns: repeat(auto-fit, minmax(200px, 1fr))
  &__item {
    padding: $base * 3 0;
    @media (max-width: $breakpoint-sm-min) {
      padding: $base * 2 0; }
    &_tags {
      // grid-column: span 2
      // @media (max-width: $breakpoint-sm-min)
 } } }      //// grid-column: span 1
