@import '../../assets/styles/_variables';

.img-favourite-wrapper {
  position: absolute;
  top: 0;
  right: $base / 2;
  background: linear-gradient(180deg, rgba($accent, 0.4) 0%, rgba($accent, 0.8) 100%);
  border-radius: $border-radius;
  z-index: 2;
  text-align: right;
  min-width: $base * 10;
  &__favourite {
    width: 100%;
    color: $text-light;
    text-align: center;
    &-title {
      text-align: center; }
    svg {
      width: $base * 5;
      height: $base * 5;
      vertical-align: middle; } } }
