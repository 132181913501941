@import '../../assets/styles/_variables';

.user-icon {
  width: $base * 12;
  height: $base * 12;
  border-radius: 50%;
  background-color: $accent;
  position: relative;
  cursor: pointer;
  transition: $transition;
  @media (max-width: $breakpoint-sm-min) {
    background-color: $text-light; }
  &_hidden {
    opacity: 0;
    pointer-events: none;
    transition: $transition; }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: $text-light;
    vertical-align: text-bottom;
    @media (max-width: $breakpoint-sm-min) {
      fill: $accent; } } }
