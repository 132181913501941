@import '../../assets/styles/_variables';
@import '../../assets/styles/_mixins';

.section-main {
  background-color: $accent; }
.main-page {
  color: $text-light;
  &__search {
    padding: $base * 4 0; }
  &__title, &__subtitle {
    text-align: center;
    text-transform: uppercase; }
  &__title {
    @include Montserrat;
    font-size: $fs-h1;
    font-weight: 600;
    line-height: $base * 15;
    @media (max-width: $breakpoint-sm-min) {
      font-size: $fs-lg;
      line-height: $base * 8;
      padding: 0 $base * 4; } }
  &__subtitle {
    font-size: $fs-lg;
    font-weight: 400;
    line-height: $base * 8;
    @media (max-width: $breakpoint-sm-min) {
      font-size: $fs-xl;
      text-transform: none; } } }
