@import './_variables';

@mixin Montserrat {
  font-family: 'Montserrat', sans-serif; }

@mixin Inter {
  font-family: 'Inter', sans-serif; }

@mixin list-null {
  margin: 0;
  padding: 0;
  list-style: none; }
