@import '../../assets/styles/_variables';

.pagination-list {
  padding-top: $base * 6;
  display: flex;
  justify-content: flex-end;
  gap: $base * 2;
  list-style: none;
  @media (max-width: $breakpoint-sm-min) {
    gap: $base * 4; }
  &__item {
    color: $accent;
    font-weight: 500;
    font-size: $fs-m;
    cursor: pointer;
    // &_break
    //   user-select: none
    &_disabled {
      color: $text-inactive;
      cursor: not-allowed;
      svg {
        path {
          fill: $text-inactive; } } }
    &_active {
      font-weight: 700; }
    @media (max-width: $breakpoint-sm-min) {
      color: $text-light; } } }
