@import '../../assets/styles/_variables';

.skeleton-details-page {
  height: 100%;
  // padding-bottom: $base * 25
  &__search {
    height: $base * 15;
    width: 100%;
    margin-top: $base * 6;
    margin-bottom: $base * 4;
    border-radius: $border-radius; }
  &-info {
    padding: $base * 2;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: minmax(45%, 600px) minmax(45%, 400px);
    grid-template-areas: "info img" "info download";
    grid-column-gap: $base * 10;
    grid-row-gap: $base * 6;
    border: 1px solid $color-border;
    border-radius: $border-radius;
    @media (max-width: $breakpoint-md-max) {
      grid-template-rows: repeat(3, auto);
      grid-template-columns: 1fr;
      grid-template-areas: 'img' 'download' 'info'; }
    @media (max-width: $breakpoint-md-min) {
      justify-content: center; }
    @media (max-width: $breakpoint-sm-min) {
      color: $text-light;
      border: none; }
    &-img {
      grid-area: img;
      width: 100%;
      height: 450px;
      border-radius: $border-radius;
      @media (max-width: $breakpoint-md-max) {
        max-width: 600px;
        justify-self: center; }
      @media (max-width: $breakpoint-sm-min) {
        max-height: 200px; } }
    &-download {
      grid-area: download;
      justify-self: center;
      width: 100%;
      @media (max-width: $breakpoint-md-max) {
        max-width: 600px; }
      &__btn {
        height: 40px; } }
    &-sub {
      width: 100%;
      grid-area: info;
      justify-self: center;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      grid-gap: clamp(1rem, $base * 4, $base * 6);
      justify-content: flex-start;
      @media (max-width: $breakpoint-xs-min) {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); }
      &__text {
        width: 100%;
        height: $base * 6;
        margin: $base * 4 0; }
 } } }      // height: 200px
