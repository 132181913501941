@import '../../assets/styles/_variables';
@import '../../assets/styles/_mixins';

.news-component {
  &__title {
    margin-top: 0;
    @media (max-width: $breakpoint-sm-min) {
      font-size: $fs-xl; } }
  &-list {
    @include list-null;
    @include Inter;
    font-size: $fs-s;
    font-weight: 300;
    overflow-y: auto;
    overflow-x: hidden;
    &__item {
      margin-bottom: $base * 4;
      cursor: pointer;
      &:hover {
        text-decoration: underline; } } } }
