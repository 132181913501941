@import '../../../../assets/styles/_variables';

// TODO: Адаптив
.report-add {
  padding: $base * 2 $base * 4;
  background-color: #fff;
  min-width: 600px;
  border-radius: $border-radius;
  @media (max-width: $breakpoint-sm-min) {
    min-width: 350px; }
  &-body {
    &__title {
      color: $accent; } }
  &-btns {
    &__item {} }
  &__control {
    margin: $base * 4 0;
    width: 100%; } }
