@import '../../../../assets/styles/_variables';

.main-gallery-wrapper {
  width: 100%;
  &__title {
    text-align: center;
    margin-bottom: $base * 10; } }
.main-gallery {
  // width: 100%
  // display: flex
  // justify-content: flex-start
 }  // flex-wrap: wrap
