@import '../../assets/styles/_variables';

.material-details-category {
  &__text {
    color: $accent;
    text-decoration: none;
    font-size: $fs-m;
    position: relative;
    @media (max-width: $breakpoint-sm-min) {
      color: $text-light; }
    &::before {
      content: '';
      width: 100%;
      height: 2px;
      background-color: $accent;
      position: absolute;
      bottom: 0;
      opacity: 0;
      transition: $transition; }
    &:hover {
      &::before {
        opacity: 1;
        transition: $transition; } } } }
