@import '../../assets/styles/_variables';

.skeleton-element {
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;
  border-radius: $border-radius;
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(#fff, 0) 0, rgba(#fff, 0.2) 20%,rgba(#fff, 0.5) 60%,rgba(#fff, 0));
    animation: shimmer 2s infinite;
    content: ''; }
  &_title {
    width: 50%;
    height: $base * 5;
    margin-bottom: $base * 4; }
  &_img {
    width: 100px;
    height: 100px;
    border-radius: 50%; }
  &_text {
    width: 100%;
    height: $base * 3; }
  &_thumbnail {
    width: 100px;
    height: 100px; }

  @keyframes shimmer {
    100% {
      transform: translateX(100%); } } }
