@import '../../../../assets/styles/_variables';
@import '../../../../assets/styles/_mixins';

.guest-material-download {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include Inter;
  &-radio {
    display: flex;
    justify-content: center;
    font-size: $fs-xs;
    &__item {
      &:first-child {
        margin-right: $base * 6; } }
    @media (max-width: $breakpoint-sm-min) {
      color: $text-light; } }
  &__title {
    color: $text-placeholder;
    font-size: $fs-xs;
    font-weight: normal;
    margin: $base * 12 0 $base * 4 0;
    text-align: center;
    @media (max-width: $breakpoint-sm-min) {
      color: $text-light; } }
  &__checkbox {
    font-size: $fs-xs; }
  &__select {
    font-size: $fs-xs; }
  &__btn {
    @include Montserrat;
    // margin-top: $base * 10
    @media (max-width: $breakpoint-sm-min) {
      border: 1px solid $text-light; } } }
