@import '../../assets/styles/_variables';

.about-page {
  color: $accent;
  @media (max-width: $breakpoint-sm-min) {
    color: $text-light; }
  &__text {
    font-size: $fs-l;
    line-height: 1.5em;
    @media (max-width: $breakpoint-sm-min) {
      color: $text-light; }
    &-medium {
      font-weight: $fw-medium; }
    &-link {
      text-decoration: none;
      color: $accent;
      font-weight: $fw-medium;
      @media (max-width: $breakpoint-sm-min) {
        color: $text-light; } } } }
