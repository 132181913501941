@import '../../assets/styles/_variables';

.material-details-tags {
  &-list {
    display: flex;
    flex-wrap: wrap;
    &__item {
      color: $text-inactive;
      text-decoration: none;
      border: 1px solid $color-border;
      font-size: $fs-s;
      padding: $base $base * 2;
      margin-right: $base * 2;
      margin-bottom: $base * 2;
      border-radius: $border-radius;
      color: $text-inactive;
      box-shadow: 2px 2px 4px rgba(0,0,0,0.05);
      transition: $transition;
      &:hover {
        transform: translateY(-$base / 2);
        transition: $transition; }
      @media (max-width: $breakpoint-sm-min) {
        border-color: $text-inactive;
        color: $text-light;
        box-shadow: none; } } } }
