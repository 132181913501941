@import '../../../../assets/styles/_variables';

.windows-wrapper {
  &-windows {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: clamp(1rem, $base * 2, $base * 4);
    justify-content: center;
    @media (max-width: $breakpoint-sm-min) {
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); }
    &__item {
      padding: $base * 6 $base * 4;
      border: 1px solid $text-inactive;
      border-radius: $border-radius;
      color: $text-light;
      box-shadow: 0 4px 4px rgba(255, 255, 255, 0.2); } } }
