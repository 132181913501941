@import '../../assets/styles/_variables';

.forbidden-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__link {
    text-decoration: none; }
  &__logo {
    width: 480px;
    height: 200px;
    @media (max-width: $breakpoint-sm-min) {
      width: 220px;
      height: 140px; } }
  &__title {
    color: $accent;
    padding: $base * 7 0; }
  &__text, &__sub-text {
    padding: $base * 2 0;
    text-align: center; }
  &__btn {
    margin-top: $base * 8; } }
