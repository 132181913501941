@import './_functions';

$base: rem(4px);

$accent: #141e61;
$accent-light: rgba(#141E61, .5);
$background: #fff;
$background-dark: #f9f9f9;

$text-main: rgba(0,0,0, 0.8);
$text-addition: rgba(0,0,0, 0.6);
$text-light: rgba(255,255,255, 0.9);
$text-inactive: #787A91;

$color-inactive: rgba(0,0,0,0.2);
$color-focus: #408CFF;
$color-link: $color-focus;
$color-danger: #FF0000;
$color-hover: #26359C;
$color-border: #EBEDF0;
$color-success: #18C148;
$color-button-grey: #A9AAB9;
$color-divider: #E1E3EB;
$color-active-cell: #1D8446;

$color-loading: rgba(20, 30, 97, 0.5);
$color-button-disable: #DADAE1;

$text-placeholder: #787A91;

$transition: .2s;

$border-radius: $base * 1.5;
$border-color: rgba(0,0,0, 0.2);

$box-shadow-base: 0 2px 8px #000;

$padding-horizontal-app: $base * 4;

// #region FS
$fs-h1: rem(44px);
$fs-lg: rem(24px);
$fs-xxl: rem(22px);
$fs-xl: rem(20px);
$fs-l: rem(18px);
$fs-m: rem(16px);
$fs-s: rem(14px);
$fs-xs: rem(12px);
$fs-xxs: rem(10px);
// #endregion

// #region FW
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-bold: 700;
// #endregion FW

// #region breakPoints
$breakpoint-xs-min: 350px;
$breakpoint-sm-min: 576px;
$breakpoint-md-min: 768px;
$breakpoint-sm-max: $breakpoint-md-min - 1;
$breakpoint-xs-max: $breakpoint-sm-min - 1;
$breakpoint-lg-min: 992px;
$breakpoint-md-max: $breakpoint-lg-min - 1;
$breakpoint-xl-min: 1200px;
$breakpoint-lg-max: $breakpoint-xl-min - 1;
$breakpoint-xxl-min: 1400px;
$breakpoint-xl-max: $breakpoint-xxl-min - 1;
$breakpoint-fhd-min: 1920px;
$breakpoint-xxl-max: $breakpoint-fhd-min - 1;
// #endregion breakPoints

$container-width: 1440px;
