@import '../../assets/styles/_variables';
@import '../../assets/styles/_mixins';

.categories-popular-list {
  @include list-null;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; }
  &__item {
    display: block;
    margin: 0 $base * 2;
    color: $text-inactive;
    font-size: $fs-s;
    font-weight: 500;
    cursor: pointer;
    transition: $transition;
    &_active {
      color: $accent;
      font-weight: 700; }
    &:hover {
      transition: $transition;
      color: $accent; } }

  &__link {
    color: $text-inactive;
    text-decoration: none; } }
