@import '../../assets/styles/_variables';

.incorrect-result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__img {
    max-width: 200px;
    max-height: 200px;
    display: block; }
  &__title {
    margin-top: $base * 15; }
  &__text {} }
