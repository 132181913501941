@import '../../assets/styles/_variables';

.author-card {
  cursor: pointer;
  text-decoration: none;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  background-color: $accent;
  color: $text-light;
  font-weight: $fw-medium;
  box-shadow: 6px 6px 10px rgba(0,0,0,0.2);
  display: grid;
  padding: $base * 2;
  grid-template-areas: 'photo name name' 'photo viewed download';
  grid-template-columns: 45% 25% 25%;
  grid-column-gap: $base * 2;
  grid-template-rows: repeat(2, 1fr);
  align-items: center;
  max-width: 468px;
  @media (max-width: $breakpoint-sm-min) {
    background-color: $text-light;
    color: $accent; }
  &__item {
    &-title {
      svg {
        margin-left: $base;
        vertical-align: top; } }
    &-value {} }
  &__name {
    grid-area: name;
    overflow: hidden;
    text-overflow: ellipsis; }
  &__viewed {
    grid-area: viewed;
    align-self: flex-start; }
  &__downloads {
    grid-area: download;
    align-self: flex-start; }
  &__img {
    grid-area: photo; } }
