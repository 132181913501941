@import '../../assets/styles/_variables';

.section-categories {
  background-color: $background-dark;
  padding-top: $base * 6;
  padding-bottom: $base * 4;
  @media (max-width: $breakpoint-md-min) {
    padding-top: $base * 8;
    padding-bottom: $base * 8; }
  @media (max-width: $breakpoint-sm-min) {
    display: none; } }

.categories-module {
  align-items: center;
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  white-space: nowrap;
  position: relative;
  &__title {
    font-size: $fs-m;
    display: block;
    color: $accent;
    &::after {
      content: '|';
      display: inline-block;
      margin-left: $base * 3; } }
  &__popular {}
  &__btn {
    margin-left: auto;
    font-size: $fs-m;
    font-weight: 400;
    color: $accent;
    display: block;
    @media (max-width: $breakpoint-md-min) {
      display: none; }
    &::before {
      content: '|';
      display: inline-block;
      margin-right: $base * 2; } } }
