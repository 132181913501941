@import '../../assets/styles/_variables';

.material-details-author {
  &__link {
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    position: relative;
    &::before {
      content: '';
      width: 100%;
      height: 2px;
      background-color: $accent;
      position: absolute;
      bottom: 0;
      opacity: 0;
      transition: $transition; }
    &:hover {
      &::before {
        opacity: 1;
        transition: $transition; } } }
  &__text {
    color: $accent;
    font-size: $fs-l;
    transition: $transition;
    margin-left: $base * 2;
    @media (max-width: $breakpoint-sm-min) {
      color: $text-light; } }
  &__img {
    svg {
      width: $base * 6;
      height: $base * 6;
      vertical-align: text-bottom;
      @media (max-width: $breakpoint-sm-min) {
        path {
          fill: $text-light; } } } } }
