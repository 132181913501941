@import '../../assets/styles/_variables';
@import '../../assets/styles/_mixins';

.registration-complete {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  @include Montserrat;
  &-top, &-bottom {
    padding: $base * 6 $base * 14;
    min-height: 250px; }
  &-top {
    background-color: $accent;
    display: flex;
    justify-content: center;
    align-items: center;
    &-logo {
      text-align: center;
      &__icon {
        width: 60%;
        height: 60%;
        fill: #fff; } } }

  &-bottom {
    background: #fff;
    text-align: center;
    &__title {
      color: $accent;
      font-size: $fs-xl; }
    &__text {
      color: $text-inactive; } } }
