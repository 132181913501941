@import '../../assets/styles/_variables';

.skeleton-filters {
  display: flex;
  flex-wrap: wrap;
  &__title {
    width: $base * 50;
    height: $base * 5; }
  &-actions {
    margin-left: auto;
    display: flex;
    &__item {
      width: $base * 19;
      height: $base * 5;
      @media (max-width: $breakpoint-sm-min) {
        width: $base * 5; }
      &+& {
        margin-left: $base * 4; } } } }
