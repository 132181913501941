@import '../../assets/styles/_variables';

.material-img {
  display: block;
  overflow: hidden;
  z-index: 1;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: $border-radius;
  &:hover {
    .cls_981374 {
      transform: translateY(0%);
      opacity: 1;
      transition: $transition; } }
  &__original {
    width: 100%;
    height: 100%;
    // display: block
    // position: relative
    object-fit: cover;
    cursor: pointer;
    &_contain {
      object-fit: contain; } } }

.cls_981374 {
  transform: translateY(-100%);
  opacity: 0;
  transition: $transition; }
