@import '../../../../assets/styles/_variables';

.list-data-wrapper {
  &_fetching {
    opacity: 0.5; }
  &__filters {
    padding-bottom: $base * 6; }
  &__table {}
  &-actions {
    margin-top: $base * 8;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    &__btn {
      @media (max-width: $breakpoint-sm-min) {
        border: 1px solid $text-inactive;
        width: 100%; } }
    &__pagination {
      margin-left: auto;
      @media (max-width: $breakpoint-sm-min) {
        margin-left: 0; } } } }
