@import '../../assets/styles/_variables';

.skeleton-table {
  height: 100%;
  &-header {
    display: flex;
    margin-bottom: $base * 4;
    padding-bottom: $base * 3;
    border-bottom: 1px solid $color-border; }
  &-row {
    display: flex;
    justify-content: space-between;
    margin: $base * 3 0;
    &__item {
      height: $base * 6;
      margin-right: $base * 2; } }
  &__btn {
    margin-top: $base * 5;
    width: 270px;
    height: $base * 11; } }
