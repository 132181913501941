@import '../../assets/styles/_variables';

.categories-wrapper {
  background: #fff;
  border-radius: $border-radius;
  box-shadow: 2px 0px 7px 1px rgba(120, 122, 145, 0.2);
  z-index: 5;
  position: absolute;
  top: 70%;
  right: $base * 3;
  padding: $base * 6;
  max-height: $base * 69;
  // max-width: $base * 140
  overflow-y: auto;
  &_hidden {
    display: none; } }

.categories-list {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4,1fr);
  grid-column-gap: $base * 10;
  &__item {
    margin-bottom: $base * 3;
    color: $text-inactive;
    font-size: $fs-s;
    font-weight: 500;
    cursor: pointer;
    transition: $transition;
    &_active {
      color: $accent;
      font-weight: 700; }
    &:hover {
      transition: $transition;
      color: $accent; } } }
