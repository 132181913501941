@import '../../../../assets/styles/_variables';
@import '../../../../assets/styles/_mixins';

.news {
  width: 100%;
  padding: $base * 10 0;
  @media (max-width: $breakpoint-sm-min) {
    padding: $base * 6 0; }
  &__news {
    grid-column: span 2;
    grid-row: span 2;
    @media (max-width: $breakpoint-sm-min) {
      grid-column: span 1;
      grid-row: span 1; } }
  &__img {} }
