@import '../../assets/styles/_variables';

.report-window {
  text-decoration: none;
  color: $accent;
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: repeat(2, 1fr);
  grid-gap: $base * 4;
  grid-template-areas: 'date status' 'interval status';
  &_deny {
    pointer-events: none; }
  &__date, &__interval {
    color: $text-addition;
    @media (max-width: $breakpoint-sm-min) {
      color: $text-light; } }
  &__date {
    grid-area: date; }
  &__interval {
    grid-area: interval;
    white-space: nowrap; }
  &__status {
    grid-area: status;
    justify-self: end;
    align-self: center;
    font-size: 1.2em;
    &_accept {
      color: $color-success; }
    &_deny {
      color: $color-danger; } } }
