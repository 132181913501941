@import '../../assets/styles/_variables';

.skeleton-materials {
  height: 100%;
  &-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-gap: clamp(1rem, 2vw, 24px);
    @media (max-width: $breakpoint-lg-max) {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); }
    @media (max-width: $breakpoint-sm-min) {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); }
    &__item {
      width: 100%;
      min-height: 450px;
      @media (max-width: $breakpoint-sm-max) {
        min-height: 350px; } } } }
