@import '../../assets/styles/_variables';
@import '../../assets/styles/_mixins';

.phone-wrapper {
  position: relative;
  label {
    font-size: $fs-s;
    color: $text-inactive;
    display: block;
    padding: 0.5em 0; }
  &__input {
    @include Inter;
    display: inline-block;
    font-size: $fs-s;
    padding: $base 0;
    background-color: transparent;
    width: 100%;
    border: none;
    border-bottom: 1px solid $text-inactive;
    outline: none;
    transition: $transition;
    &::placeholder {
      transition: $transition;
      color: $text-placeholder; }
    &:invalid {
      transition: $transition;
      border-color: red; }
    &_error {
      border-color: red; }
    &:focus:not(&_error) {
      transition: $transition;
      color: $color-focus;
      border-color: $color-focus; } } }
