@import '../../assets/styles/_variables';

.exclusive-rights-component {
  padding: $base * 2 0;
  display: flex;
  align-items: center;
  gap: $base * 2;
  &__icon {
    fill: $accent; }
  &__text {
    color: $text-inactive;
    font-size: $fs-s; } }
